import React, { useState, useEffect, useRef } from 'react'
import Header from '../Utilities/Header';
import Footer from '../Utilities/Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import "../Sass/Home.scss"
import fb from "../Firebase/firebase";
import cream from "../Images/Products/cream 2.jpg"
import tab from "../Images/Products/tablet.jpg"
import banner from "../Images/Homepage/banner.png"
import { Link } from 'react-router-dom';
const DB = fb.firestore()
const CF = DB.collection('Veinosil_Form');

const Home2 = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        // const href = window.location.href.substring(
        //     window.location.href.lastIndexOf('#') + 1
        // );
        // const element = document.getElementById(href);
        // if (element) {
        //     element.scrollIntoView({ behavior: 'smooth' });
        // }
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1,
        );
        if (window.location.href.lastIndexOf('#') > 0) {
            document.getElementById(href)?.scrollIntoView();
        }

    }, []);

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Quantity: "",
        RetailerSelfUse: ""
    });

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Quantity, RetailerSelfUse, Products } = userData;
        if (Name && Email && Mobile && Quantity && RetailerSelfUse && Products) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                toast.warning('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    toast.warning("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    toast.warning("Please enter 10 digit Mobile number.");
                    return
                }
            }

            try {

                CF.add({
                    Name: Name,
                    Email: Email,
                    Mobile: Mobile,
                    Quantity: Quantity,
                    RetailerSelfUse: RetailerSelfUse,
                    Products: Products
                }).then((docRef) => {
                    toast.success('Order Received Successfully');
                }).catch((error) => {
                    console.error("error:", error);
                });


                // emailjs.sendForm('service_91sqknx', 'template_4j6wwya', form.current, '6pbmyufjSURA60XeS')
                //     .then((result) => {
                //         console.log(result.text);

                //     }, (error) => {
                //         console.log(error.text);
                //     });

                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Quantity: "",
                    RetailerSelfUse: "",
                    Products: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                toast.warning("Error adding document")
            }
        }
        else {
            toast.warning('Please fill the data');
        }
    };


    // slider

    // const itemsslider = [
    //     <div className="banner-card">
    //         <div className="banner-text">
    //             <h3>Welcome to Veinosil</h3>
    //             <p>Veinosil is a pharmaceutical
    //                 formulation designed to support vascular
    //                 health and enhance blood circulation.
    //                 Veinosil Forte treats chronic venous
    //                 insufficiency and associated vascular
    //                 disorders. Veinosil Cream is a topical
    //                 formulation for the management of
    //                 varicose veins. Veinosil Forte serves as an
    //                 adjunct therapy for major vascular
    //                 conditions, while Veinosil Cream acts as
    //                 an adjunct therapy for the topical
    //                 management of venous insufficiency.</p>
    //             <div className="btn">
    //                 <a href="#">Buy Now</a>
    //                 <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><g id="_05.cursor" data-name="05.cursor"><path d="m498.59 383-152.06-152.07 103-49.76c32.4-15.65 29.82-63.47-4.47-74.34-.62-.2-1.25-.38-1.89-.55l-391.17-104.83c-30.7-8.22-58.77 19.85-50.55 50.55l104.83 391.21c.17.64.35 1.27.55 1.89 10.87 34.29 58.69 36.87 74.34 4.47l49.76-103 152.07 152.02a45.76 45.76 0 0 0 64.73 0l50.87-50.87a45.76 45.76 0 0 0 -.01-64.72z" /></g></g></svg>
    //             </div>
    //         </div>
    //         <img className='first' src={tab} alt="product2" />
    //     </div>,
    //     <div className="banner-card">
    //         <div className="banner-text">
    //             <h3>Welcome to Veinosil</h3>
    //             <p>Veinosil is a pharmaceutical
    //                 formulation designed to support vascular
    //                 health and enhance blood circulation.
    //                 Veinosil Forte treats chronic venous
    //                 insufficiency and associated vascular
    //                 disorders. Veinosil Cream is a topical
    //                 formulation for the management of
    //                 varicose veins. Veinosil Forte serves as an
    //                 adjunct therapy for major vascular
    //                 conditions, while Veinosil Cream acts as
    //                 an adjunct therapy for the topical
    //                 management of venous insufficiency.</p>
    //             <div className="btn">
    //                 <a href="#">Buy Now</a>
    //                 <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><g id="_05.cursor" data-name="05.cursor"><path d="m498.59 383-152.06-152.07 103-49.76c32.4-15.65 29.82-63.47-4.47-74.34-.62-.2-1.25-.38-1.89-.55l-391.17-104.83c-30.7-8.22-58.77 19.85-50.55 50.55l104.83 391.21c.17.64.35 1.27.55 1.89 10.87 34.29 58.69 36.87 74.34 4.47l49.76-103 152.07 152.02a45.76 45.76 0 0 0 64.73 0l50.87-50.87a45.76 45.76 0 0 0 -.01-64.72z" /></g></g></svg>
    //             </div>
    //         </div>
    //         <img className='second' src={cream} alt="Banner" />
    //     </div>,
    // ];

    // const responsive = {
    //     0: { items: 1 }
    // };

    // slider ends

    // products
    const items = [
        <div className="products-card">
            <div className="products-text">
                <h3>Veinosil Forte</h3>
                <p>Veinosil Forte is a clinically advanced
                    formulation designed for the
                    management of chronic venous
                    insufficiency and vascular disorders. The
                    product combines calcium dobesilate,
                    citrus bioflavonoids, and vitamin C.
                    Veinosil Forte works to improve venous
                    tone, reduce capillary permeability, and
                    support overall vascular health. Overall,
                    Veinosil Forte helps in achieving optimal
                    vascular well-being.
                </p>
                <div className="btn">
                    <Link to={"/products/veinosil-forte"} className='learn'>Learn More</Link>
                    <a href="#buy-now" className='learn'>Buy Now</a>

                    {/* <div className="btn-body">
                        <a href="#buy-now">Buy Now</a>
                        <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><g id="_05.cursor" data-name="05.cursor"><path d="m498.59 383-152.06-152.07 103-49.76c32.4-15.65 29.82-63.47-4.47-74.34-.62-.2-1.25-.38-1.89-.55l-391.17-104.83c-30.7-8.22-58.77 19.85-50.55 50.55l104.83 391.21c.17.64.35 1.27.55 1.89 10.87 34.29 58.69 36.87 74.34 4.47l49.76-103 152.07 152.02a45.76 45.76 0 0 0 64.73 0l50.87-50.87a45.76 45.76 0 0 0 -.01-64.72z" /></g></g></svg>
                    </div> */}
                </div>
            </div>
            <div className="products-img">
                <img src={tab} alt="forte" />

            </div>
        </div>,
        <div className="products-card">
            <div className="products-text">
                <h3>Veinosil Cream</h3>
                <p>Veinosil Cream is a dual-action emollient and vasotonic solution that revitalizes
                    your skin while promoting healthy blood circulation. Infused with bioactive
                    salts, it is designed to synergistically support vascular health and skin
                    nourishment. By soothing and hydrating the skin, Veinosil Cream reduces
                    discomfort and heaviness in the legs, leaving your skin deeply nourished.
                </p>
                <div className="btn">
                    <Link to={"/products/veinosil-cream"} className='learn'>Learn More</Link>
                    <a href="#buy-now" className='learn'>Buy Now</a>

                    {/* <div className="btn-body">
                        <a href="#buy-now">Buy Now</a>
                        <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><g id="_05.cursor" data-name="05.cursor"><path d="m498.59 383-152.06-152.07 103-49.76c32.4-15.65 29.82-63.47-4.47-74.34-.62-.2-1.25-.38-1.89-.55l-391.17-104.83c-30.7-8.22-58.77 19.85-50.55 50.55l104.83 391.21c.17.64.35 1.27.55 1.89 10.87 34.29 58.69 36.87 74.34 4.47l49.76-103 152.07 152.02a45.76 45.76 0 0 0 64.73 0l50.87-50.87a45.76 45.76 0 0 0 -.01-64.72z" /></g></g></svg>
                    </div> */}
                </div>
            </div>
            <div className="products-img">
                <img src={cream} alt="forte" />
            </div>
        </div>
        ,
    ];

    const responsive2 = {
        0: { items: 1 }
    };

    // products


    return (
        <>
            <Header />
            <ToastContainer />
            <div className="banner">
                <div className="banner-card">
                    <div className="banner-text">
                        <h3>Welcome to Veinosil</h3>
                        <p>Veinosil is a pharmaceutical
                            formulation designed to support vascular
                            health and enhance blood circulation.
                            Veinosil Forte treats chronic venous
                            insufficiency and associated vascular
                            disorders. Veinosil Cream is a topical
                            formulation for the management of
                            varicose veins. Veinosil Forte serves as an
                            adjunct therapy for major vascular
                            conditions, while Veinosil Cream acts as
                            an adjunct therapy for the topical
                            management of venous insufficiency.</p>
                        <div className="btn">
                            <a href="#products">Our Products</a>
                        </div>
                    </div>
                    <div className="img">
                        <img className='first' src={banner} alt="product2" />

                    </div>
                </div>
            </div>

            <div id='products' className="products">
                <div className="title">
                    <h4>Our Products</h4>
                    <p></p>
                </div>

                <div className="products-body" >

                    <div className="products-card">
                        <div className="products-img">
                            <img src={tab} alt="forte" />
                        </div>
                        <div className="products-text">
                            <h3>Veinosil Forte</h3>
                            <p>Veinosil Forte is a clinically advanced
                                formulation designed for the
                                management of chronic venous
                                insufficiency and vascular disorders. The
                                product combines calcium dobesilate,
                                citrus bioflavonoids, and vitamin C.
                                {/* Veinosil Forte works to improve venous
                                tone, reduce capillary permeability, and
                                support overall vascular health. Overall,
                                Veinosil Forte helps in achieving optimal
                                vascular well-being. */}
                            </p>
                            <div className="btn">
                                <Link to={"/products/veinosil-forte"} className='learn'>Learn More</Link>
                                <a href="#buy-now" className='learn'>Buy Now</a>
                            </div>
                        </div>

                    </div>
                    <div className="products-card">
                        <div className="products-img">
                            <img src={cream} alt="forte" />
                        </div>
                        <div className="products-text">
                            <h3>Veinosil Cream</h3>
                            <p>Veinosil Cream is a dual-action emollient and vasotonic solution that revitalizes
                                your skin while promoting healthy blood circulation. Infused with bioactive
                                salts, it is designed to synergistically support vascular health and skin
                                nourishment.
                                {/* By soothing and hydrating the skin, Veinosil Cream reduces
                                discomfort and heaviness in the legs, leaving your skin deeply nourished. */}
                            </p>
                            <div className="btn">
                                <Link to={"/products/veinosil-cream"} className='learn'>Learn More</Link>
                                <a href="#buy-now" className='learn'>Buy Now</a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div id="buy-now"></div>
            
            <div className="contact">
                <div className="title">
                    <h4>Buy Now</h4>
                    <p></p>
                </div>
                <div className="contact-page-body">
                    <form method='POST' ref={form}>
                        {/* <h3>Send Us a Message</h3> */}

                        <div className="form-body">
                            <div className="input">
                                <label>Name*</label>
                                <input type="text" placeholder='Name'
                                    name='Name'
                                    value={userData.Name}
                                    onChange={postUserData}
                                    required />
                            </div>

                            <div className="input">
                                <label>Email*</label>
                                <input type="email" placeholder='Email'

                                    name='Email'
                                    value={userData.Email}
                                    onChange={postUserData}
                                    required />
                            </div>

                            <div className="input">
                                <label>Phone*</label>
                                <input type="text" placeholder='Mobile'
                                    name='Mobile'
                                    value={userData.Mobile}
                                    onChange={postUserData}
                                    required />
                            </div>

                            <div className="input">
                                <label>Quantity*</label>
                                <input type="number" min={1} placeholder='Quantity'
                                    name='Quantity'
                                    value={userData.Quantity}
                                    onChange={postUserData}
                                    required />
                            </div>

                            <div className="input">
                                <label>Retailer / Self Use*</label>
                                <select name='RetailerSelfUse'
                                    value={userData.RetailerSelfUse}
                                    onChange={postUserData}>
                                    <option hidden selected="selected" disabled="disabled">Retailer / Self Use</option>
                                    <option value="Retailer">Retailer</option>
                                    <option value="Self Use">Self Use</option>
                                </select>
                            </div>

                            <div className="input">
                                <label>Select Product*</label>
                                <select name="Products"
                                    value={userData.Products}
                                    onChange={postUserData}>
                                    <option hidden selected="selected" disabled="disabled">Select Product</option>
                                    <option value="Veinosil-Forte">Veinosil-Forte</option>
                                    <option value="Veinosil-Cream">Veinosil-Cream</option>
                                </select>
                            </div>
                        </div>



                        <button onClick={SubmitData}>Place Order</button>
                    </form>
                    {/* <div className="contact-info">
                        <h3>Contact Information</h3>

                        <a href="#">
                            <div className="svg">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                            </div>

                            <p> WISE FUNDS
                                G-05,&nbsp; Ground floor,&nbsp; Sector-3,&nbsp;
                                Noida 201301</p>
                        </a>

                        <a href="#">
                            <div className="svg">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                            </div>

                            <p>+91-1204114832</p>
                        </a>

                        <a href="#">
                            <div className="svg">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
                            </div>

                            <p>8929541454</p>
                        </a>

                        <a href="mailto:contactus@wisefunds.in">
                            <div className="svg">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                            </div>

                            <p>contactus@wisefunds.in</p>
                        </a>

                        <a href="mailto:contactus@wisefunds.in">
                            <div className="svg">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z" /></svg>
                            </div>

                            <p>Schedule a Phone /Zoom Call</p>
                        </a>
                    </div> */}
                </div>
                {/* <div className="contact-body">
                    <form action="POST" ref={form}>
                        <div className="form">
                            <input type="text" placeholder='Name'
                                name='Name'
                                value={userData.Name}
                                onChange={postUserData}
                                required />
                            <input type="email" placeholder='Email'

                                name='Email'
                                value={userData.Email}
                                onChange={postUserData}
                                required />
                            <input type="text" placeholder='Mobile'
                                name='Mobile'
                                value={userData.Mobile}
                                onChange={postUserData}
                                required />
                            <input type="number" min={1} placeholder='Quantity'
                                name='Quantity'
                                value={userData.Quantity}
                                onChange={postUserData}
                                required />

                            <select name='RetailerSelfUse'
                                value={userData.RetailerSelfUse}
                                onChange={postUserData}>
                                <option hidden selected="selected" disabled="disabled">Retailer / Self Use</option>
                                <option value="Retailer">Retailer</option>
                                <option value="Self Use">Self Use</option>
                            </select>
                            <select name="Products"
                                value={userData.Products}
                                onChange={postUserData}>
                                <option hidden selected="selected" disabled="disabled">Select Product</option>
                                <option value="Veinosil-Forte">Veinosil-Forte</option>
                                <option value="Veinosil-Cream">Veinosil-Cream</option>
                            </select>
                        </div>

                        <button onClick={SubmitData}>Place Order</button>
                    </form>
                </div> */}


            </div>

            <Footer />
        </>
    )
}

export default Home2