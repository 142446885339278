import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import fb from "../Firebase/firebase";
import HeaderInner from './HeaderInner';
import Footer from './Footer';

const DB = fb.firestore()
const CF = DB.collection('Veinosil_Form');

const BuyNow = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1,
        );
        if (window.location.href.lastIndexOf('#') > 0) {
            document.getElementById(href)?.scrollIntoView();
        }

    }, []);

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Quantity: "",
        RetailerSelfUse: ""
    });

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Quantity, RetailerSelfUse, Products } = userData;
        if (Name && Email && Mobile && Quantity && RetailerSelfUse && Products) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                toast.warning('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    toast.warning("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    toast.warning("Please enter 10 digit Mobile number.");
                    return
                }
            }

            try {

                CF.add({
                    Name: Name,
                    Email: Email,
                    Mobile: Mobile,
                    Quantity: Quantity,
                    RetailerSelfUse: RetailerSelfUse,
                    Products: Products
                }).then((docRef) => {
                    toast.success('Order Received Successfully');
                }).catch((error) => {
                    console.error("error:", error);
                });


                // emailjs.sendForm('service_91sqknx', 'template_4j6wwya', form.current, '6pbmyufjSURA60XeS')
                //     .then((result) => {
                //         console.log(result.text);

                //     }, (error) => {
                //         console.log(error.text);
                //     });

                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Quantity: "",
                    RetailerSelfUse: "",
                    Products: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                toast.warning("Error adding document")
            }
        }
        else {
            toast.warning('Please fill the data');
        }
    };

    return (
        <>
            <ToastContainer />
            <HeaderInner />
            <div className="contact" style={{marginTop: "5%"}}>
                <div className="title">
                    <h4>Buy Now</h4>
                    <p></p>
                </div>
                <div className="contact-page-body">
                    <form method='POST' ref={form}>
                        {/* <h3>Send Us a Message</h3> */}

                        <div className="form-body">
                            <div className="input">
                                <label>Name*</label>
                                <input type="text" placeholder='Name'
                                    name='Name'
                                    value={userData.Name}
                                    onChange={postUserData}
                                    required />
                            </div>

                            <div className="input">
                                <label>Email*</label>
                                <input type="email" placeholder='Email'

                                    name='Email'
                                    value={userData.Email}
                                    onChange={postUserData}
                                    required />
                            </div>

                            <div className="input">
                                <label>Phone*</label>
                                <input type="text" placeholder='Mobile'
                                    name='Mobile'
                                    value={userData.Mobile}
                                    onChange={postUserData}
                                    required />
                            </div>

                            <div className="input">
                                <label>Quantity*</label>
                                <input type="number" min={1} placeholder='Quantity'
                                    name='Quantity'
                                    value={userData.Quantity}
                                    onChange={postUserData}
                                    required />
                            </div>

                            <div className="input">
                                <label>Retailer / Self Use*</label>
                                <select name='RetailerSelfUse'
                                    value={userData.RetailerSelfUse}
                                    onChange={postUserData}>
                                    <option hidden selected="selected" disabled="disabled">Retailer / Self Use</option>
                                    <option value="Retailer">Retailer</option>
                                    <option value="Self Use">Self Use</option>
                                </select>
                            </div>

                            <div className="input">
                                <label>Select Product*</label>
                                <select name="Products"
                                    value={userData.Products}
                                    onChange={postUserData}>
                                    <option hidden selected="selected" disabled="disabled">Select Product</option>
                                    <option value="Veinosil-Forte">Veinosil-Forte</option>
                                    <option value="Veinosil-Cream">Veinosil-Cream</option>
                                </select>
                            </div>
                        </div>



                        <button onClick={SubmitData}>Place Order</button>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default BuyNow