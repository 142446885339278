import React, { useEffect } from 'react'
import Footer from '../Utilities/Footer'
import HeaderInner from '../Utilities/HeaderInner';
import "../Sass/PrivacyPolicy.scss"

const PrivacyPolicy = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <HeaderInner />
            <div className="privacy-policy">

                <div className="title">
                    <h4>Privacy Policy</h4>
                    <p></p>
                </div>
                <p>Welcome to Veinosil, a product of Akcent Health Care. We are committed to protecting your
                    privacy and ensuring a safe experience. This Privacy Policy outlines how we collect, use, and
                    safeguard your personal information when you visit our website and interact with our products and
                    services.</p>
                {/* <p>Welcome to Akcent Healthcare Pvt Ltd ("us", "we", or "our"). Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you visit our website.</p> */}


                <h3>Information We Collect</h3>
                <p>We collect the following types of information:</p>
                <ol>
                    <li>Personal Information: <br />
                        <ul>
                            <li>Name</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                            <li>Any other information you provide when you contact us or make a purchase</li>
                        </ul>
                    </li>

                    <li>Usage Data:<br />
                        <ul>
                            <li>Information about your visit to our website, including IP address, browser type, and
                                usage patterns.</li>
                        </ul>
                    </li>

                    <li>Cookies and Tracking:

                        <ul>
                            <li>We may use cookies, which are similar to tracking technologies, to enhance your
                                experience on our site.</li>
                        </ul>
                    </li>
                </ol>

                <h3>How We Use the Information</h3>
                <p>We may use the information we collect from you in the following ways</p>
                <ol>
                    <li>To Provide Services:

                        <ul>
                            <li>Process transactions</li>
                            <li>Deliver products</li>
                            <li>Provide customer support</li>
                        </ul>
                    </li>

                    <li>To Communicate With You:

                        <ul>
                            <li>Send you updates</li>
                            <li>Respond to your inquiries</li>
                        </ul>
                    </li>

                    <li>To Ensure Security:
                        <ul>
                            <li>Monitor and prevent fraudulent activities and unauthorized access to our systems.</li>
                        </ul>
                    </li>
                </ol>

                <h3>Data Security</h3>
                <p>We use appropriate technical and organizational measures to protect your personal data from
                    unauthorized access, disclosure, alteration, or destruction.</p>

                <h3>Your Rights</h3>
                <p>You have the following rights:</p>

                <ol>
                    <li>Access:
                        <ul>
                            <li>The right to request access to the personal data we hold about you.
                            </li>
                        </ul>
                    </li>

                    <li>Correction:
                        <ul>
                            <li>The right to request corrections of any inaccurate or incomplete data.</li>
                        </ul>
                    </li>
                </ol>

                <h3>Changes To This Policy</h3>
                <p>We may update this Privacy Policy from time to time. When we make changes, we encourage you to
                    review the policy periodically to stay informed about how we are protecting your information.</p>

                <h3>Contact Us</h3>
                <p>If you have any questions or concerns about this Privacy Policy or our data practices, please
                    contact us at:</p>

                <h3>Akcent Health Care</h3>
                <ul>
                    <li>[Address -7/27 ,Block 8 Kirti Nagar Industrial Area ]</li>
                    <li> [City, State, Zip] [Delhi ,Delhi ,110015]</li>
                    <li> [Phone Number] [01145009554]</li>
                </ul>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy
